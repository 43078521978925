@import 'bootstrap/scss/bootstrap.scss';

@mixin shadow {
  -webkit-box-shadow: 3px 5px 5px 0px rgba(0, 0, 0, 0.59);
  -moz-box-shadow: 3px 5px 5px 0px rgba(0, 0, 0, 0.59);
  box-shadow: 3px 5px 5px 0px rgba(0, 0, 0, 0.59);
}

.element-notify {
  @include shadow();
  border: none;
  font-size: 0.95rem;
  margin-top: 3em;
  padding: 0.5em;
  width: 25em;

  &.element-notify.info {
    background: lighten($dark, 2%);
    color: white;
    &:after {
      height: 100%;
      top: 0;
    }
  }

  &.element-notify.success {
    background: lighten($dark, 2%);
    color: white;
    &:after {
      height: 100%;
      top: 0;
    }
  }

  &.element-notify.warn {
    background: lighten($dark, 2%);
    color: white;
    &:after {
      height: 100%;
      top: 0;
    }
  }

  &.element-notify.error {
    background: lighten($dark, 2%);
    color: white;
    &:after {
      height: 100%;
      top: 0;
    }
  }

  .element-notify-message {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  svg {
    height: 2em;
    flex-grow: 0;
    flex-shrink: 0;
    width: 2em;
  }
}
