/* You can add global styles to this file, and also import other style files */
@import '@element/notify/lib/element-notify.css';
@import 'bootstrap/scss/bootstrap.scss';
@import 'assets/scss/notify';

$modal-min-width: 342px;

.modal-content {
  min-width: $modal-min-width;
  banner-ngbd-modal-confirm {
    min-width: $modal-min-width;
  }
}

.icon {
  width: 1rem; height: 1rem;
  position: relative;
  top: -.125rem;
  fill: currentColor;
  &.fill-green {fill: $green;stroke: $green;}
  &.fill-red {fill: $red;stroke: $red;}
  &.size-2 {width: 1.5em; height: 1.5em;}
  &.size-3 {width: 2em; height: 2em;}
}
